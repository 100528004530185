function tastesInit() {
  const swiper = new Swiper('.tastes__slider', {
    slidesPerView: 2,
    spaceBetween: 80,
    
    navigation: {
      nextEl: '.tastes .swiper-navigation__btn--next',
      prevEl: '.tastes .swiper-navigation__btn--prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 35,
      },

      1280: {  
        slidesPerView: 2,
        spaceBetween: 40,
      },

      1440: {  
        spaceBetween: 80,
      },
    }
  });
}

export { tastesInit }