function recipesInit() {
  const swiper = new Swiper('.recipes__slider', {
    slidesPerView: 2,
    spaceBetween: 50,
    
    navigation: {
      nextEl: '.recipes .swiper-navigation__btn--next',
      prevEl: '.recipes .swiper-navigation__btn--prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 24,
      },

      1024: {  
        slidesPerView: 2,
        spaceBetween: 24,
      },

      1280: {  
        spaceBetween: 40,
      },

      1440: {  
        spaceBetween: 50,
      },
    }
  });
}

export { recipesInit }