function welcomeInit() {
  const swiper = new Swiper('.welcome__slider', {
    slidesPerView: 2,
    spaceBetween: 20,
    
    navigation: {
      nextEl: '.welcome .swiper-navigation__btn--next',
      prevEl: '.welcome .swiper-navigation__btn--prev',
    },

    breakpoints: {
      0: {
        spaceBetween: 10,
      },

      1280: {  
        spaceBetween: 20,
      },
    }
  });
}

export { welcomeInit }